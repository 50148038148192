<template>
    <div class="other-data">
        <div class="content" v-if="type ==='workInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">公司：</span>
                    <span>{{otherInfo.companyName}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                  <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                    <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                  </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">职位：</span>
                <span>{{otherInfo.position}}</span>
            </div>
            <div class="info">
                <span class="content-title">公司所在地：</span>
                <span>{{otherInfo.address}}</span>
            </div>
            <div class="info">
                <span class="content-title">说明：</span>
                <span>{{otherInfo.content}}</span>
            </div>
            <div class="info">
                <span class="content-title">就职情况：</span>
                <span v-if="otherInfo.type === 1">目前在职，{{otherInfo.time}} 至今</span>
                <span v-if="otherInfo.type === 2">目前离职，{{otherInfo.time[0]}} 至 {{otherInfo.time[1]}}</span>
                <span v-if="otherInfo.type === 3">已退休，{{otherInfo.time[0]}} 至 {{otherInfo.time[1]}}</span>
            </div>
        </div>
        <OtherFrom v-if="type === 'workInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

        <div class="content" v-if="type ==='projectInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">项目名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                      <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="info">
              <span class="content-title">项目地点：</span>
              <span>{{otherInfo.address}}</span>
            </div>
            <div class="info">
                <span class="content-title">项目实施详情：</span>
                <span>{{otherInfo.content}}</span>
            </div>
          <div class="info">
            <span class="content-title">项目日期：</span>
            <span v-if="otherInfo.time">{{otherInfo.time[0]}} 至 {{otherInfo.time[1]}}</span>
          </div>
        </div>
        <OtherFrom v-if="type === 'projectInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

        <div class="content" v-if="type ==='honorInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">荣誉名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                  <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                    <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                  </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">荣誉说明：</span>
                <span>{{otherInfo.content}}</span>
            </div>
          <div class="info">
            <span class="content-title">获得荣誉时间：</span>
            <span>{{otherInfo.time}}</span>
          </div>
          <div style="display: flex" v-if="otherInfo.images">
            <div v-if="otherInfo.images.length > 0">
              <img v-for="(item, index) in otherInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
            </div>
          </div>
        </div>
        <OtherFrom v-if="type === 'honorInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

        <div class="content" v-if="type ==='professionalInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">职称名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                  <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                    <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 0" @click="submitMajor('positionalTitles')" style="background-color: #2970FF">
                    申请专业认证
                  </div>
                  <div class="replay-btn" v-if="otherInfo.status === 1" style="background-color: #FF9800">
                    正在进行认证
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 2" style="background-color: #5BC130">
                    认证通过
                  </div>
                </div>
            </div>
          <div class="info">
            <span class="content-title">专业：</span>
            <span>{{otherInfo.major}}</span>
          </div>
          <div class="info">
            <span class="content-title">评审组织：</span>
            <span>{{otherInfo.organization}}</span>
          </div>
            <div class="info">
                <span class="content-title">职称等级：</span>
                <span v-if="otherInfo.lv === 1">中级</span>
                <span v-if="otherInfo.lv === 2">高级</span>
                <span v-if="otherInfo.lv === 3">提高</span>
            </div>
            <div class="info">
              <span class="content-title">证书时间：</span>
              <span>{{otherInfo.time}}</span>
            </div>
            <div>
              <div style="display: flex" v-if="otherInfo.images">
                <div v-if="otherInfo.images.length > 0">
                  <img v-for="(item, index) in otherInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
                </div>
              </div>
            </div>
        </div>
        <OtherFrom v-if="type === 'professionalInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

        <div class="content" v-if="type ==='NVQCartInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">证书名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                  <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                    <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 0" @click="submitMajor('occupation')" style="background-color: #2970FF">
                    申请专业认证
                  </div>
                  <div class="replay-btn" v-if="otherInfo.status === 1" style="background-color: #FF9800">
                    正在进行认证
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 2" style="background-color: #5BC130">
                    认证通过
                  </div>
                </div>
            </div>
            <div class="info">
              <span class="content-title">证书时间：</span>
              <span>{{otherInfo.time}}</span>
            </div>
            <div>
              <div style="display: flex" v-if="otherInfo.images">
                <div v-if="otherInfo.images.length > 0">
                  <img v-for="(item, index) in otherInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
                </div>
              </div>
            </div>
        </div>
        <OtherFrom v-if="type === 'NVQCartInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

        <div class="content" v-if="type ==='otherCartInfo' && updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">证书名称：</span>
                    <span>{{otherInfo.name}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="otherInfo.status === 0" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                  <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                    <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 0" @click="submitMajor('otherCert')" style="background-color: #2970FF">
                    申请专业认证
                  </div>
                  <div class="replay-btn" v-if="otherInfo.status === 1" style="background-color: #FF9800">
                    正在进行认证
                  </div>
                  <div class="replay-btn hand op" v-if="otherInfo.status === 2" style="background-color: #5BC130">
                    认证通过
                  </div>
                </div>
            </div>
            <div>
              <div style="display: flex" v-if="otherInfo.images">
                <div v-if="otherInfo.images.length > 0">
                  <img v-for="(item, index) in otherInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
                </div>
              </div>
            </div>
        </div>
        <OtherFrom v-if="type === 'otherCartInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>

      <div class="content" v-if="type ==='otherInfo' && updateShow === false">
        <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
          <div style="width: 72%">
            <span class="content-title">佐证名称：</span>
            <span>{{otherInfo.name}}</span>
          </div>
          <div style="display: flex">
            <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
              <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
            </div>
            <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
              <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
            </div>
            <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
              <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
            </div>
          </div>
        </div>
        <div class="info">
          <span class="content-title">说明：</span>
          <span>{{otherInfo.content}}</span>
        </div>
        <div style="display: flex; justify-content: space-between" v-if="otherInfo.images">
          <div style="display: flex" v-if="otherInfo.images">
            <div v-if="otherInfo.images.length > 0">
              <img v-for="(item, index) in otherInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
            </div>
          </div>
        </div>
      </div>
      <OtherFrom v-if="type === 'otherInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OtherFrom>
    </div>
</template>

<script>

import OtherFrom from "./OtherFrom";
export default {
    name: "OtherData",
    components: {OtherFrom},
    data() {
        return {
            otherInfo: {},
            updateShow: false,
        }
    },
    props: {
        otherData: {
            type: Object,
            default() {
                return {}
            }
        },

        type: {
          type: String,
          default() {
            return '';
          }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.otherInfo = this.otherData
    },
    methods: {
        // 提交专业认证
        submitMajor(e) {
          let that = this;
          let toAuth = false;
          this.$emit('getAuth', '', res => {
            toAuth = res
          })
          if (that.user.authenticationStatus > 0) {
            if (toAuth) {
              that.utils.confirm('请确认提交审核内容，审核通过后将无法修改', function () {
                that.newApi.submitMajorAudit({type: e, name: that.otherInfo.name}).then(res => {
                  if (res.isSuccess === 1) {
                    that.$emit('reMajor')
                  }
                })
              })
            } else {
              that.utils.msg('提交审核必须填写：工作经历，项目经历，获取荣誉，其他佐证，至少一项作为审核参考，请完善认证信息')
            }
          } else {
            that.utils.msg('未通过实名认证，无法申请')
          }
        },

        // 修改展示
        changeShow() {
          this.$emit('upOtherData', this.otherInfo)
        },

        // 删除
        deleteInfo() {
            this.$emit('delOtherData')
        },

        // 更新
        updateChange(item) {
            this.updateShow = false;
            this.$emit('upOtherData', item)
        },

        getMajor() {
            this.updateShow = false;
            this.$emit('reMajor', this.otherData.type)
        },
    }
}
</script>

<style scoped>
.other-data {
  margin-bottom: 10px;
}

.content {
    font-size: 18px;
    padding: 20px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #BDBFC1;
}

.info {
    margin-bottom: 20px;
}

.content-title {
  color: #999999;
}

.content-title-img {
  font-weight: 600;
}

.replay-btn {
  width: 120px;
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
  border-radius: 20px;
  margin-left: 10px;
  color: #FFFFFF;
}

.other-data .info>>>img {
  max-width: 100%;
  max-height: 100%;
}

.other-data .el-checkbox{
  margin-right:0;
}

/*公共*/
.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
</style>
