<template>
  <div class="auth-image">
    <el-upload
        ref="upload"
        action="#"
        list-type="picture-card"
        :on-exceed="handleExceed"
        :limit="6"
        :file-list="fileList"
        :http-request="updateImages">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" alt=""
        >
        <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: 'AuthImage',
  components: {},
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: []
    }
  },

  props: {
    images: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
  },

  mounted() {
    if (this.images.length > 0) {
      this.fileList = this.images
    }
  },

  methods: {
    // 返回图片
    returnImages() {
      this.$emit('getImages', this.fileList)
    },
    updateImages(file) {
      let param = file.file
      let that = this
      this.utils.upload(param,function(urls){
        if (!urls){
          return false;
        }
        const uid = that.$refs.upload.uploadFiles.slice(-1)[0].uid
        const url = urls.url
        that.fileList.push({url,uid})
        that.returnImages()
      })
    },
    handleRemove(file) {
      var number = '';
      // 遍历上传列表，如果当前需要删除的图片的file.uid和列表中的uid唯一值相等，记住当前的【number 索引】
      this.$refs.upload.uploadFiles.forEach((v, i) => {
        v.uid == file.uid ? (number = i) : '';
      });
      // 根据索引，把这个图片剔除出去，这样就能使用默认方法，把图片逻辑剔除
      // 但是其实此时图片已经上传到阿里云里了的，我们删除不了阿里云里面的图片，只能在代码层面，进行逻辑删除
      this.$refs.upload.uploadFiles.splice(number, 1);
      // 同时我们自定义的fileList中，也要把这个图片逻辑删除
      this.fileList.splice(number, 1);
      this.returnImages()
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 6 张图片，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  }
}
</script>

<style scoped>

</style>
