<template>
    <div class="other-from">
        <el-form v-if="type === 'workInfo'" :model="otherInfo" :rules="workRules" :ref="type" class="demo-ruleForm">
          <div style="display: flex">
            <el-form-item prop="companyName" style="margin-bottom: 20px; flex: 1; margin-right: 30px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">公司名称</span></div>
              <el-input type="text"
                        resize="none"
                        maxlength="50"
                        :show-word-limit="true"
                        v-model="otherInfo.companyName">
              </el-input>
            </el-form-item>
            <el-form-item prop="position" style="margin-bottom: 20px; flex: 1">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">职位</span></div>
              <el-input type="text"
                        resize="none"
                        maxlength="20"
                        :show-word-limit="true"
                        v-model="otherInfo.position">
              </el-input>
            </el-form-item>
          </div>

            <el-form-item prop="address" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">地址</span></div>
                <el-input type="text"
                          resize="none"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="otherInfo.address">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">说明</span></div>
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          v-model="otherInfo.content"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin: 10px 0">
                <div style="width: 300px;">
                  <el-form-item prop="type">
                      <el-radio @input="changeType" v-model="otherInfo.type" :label="1">目前在职</el-radio>
                      <el-radio @input="changeType" v-model="otherInfo.type" :label="2">已离职</el-radio>
                      <el-radio @input="changeType" v-model="otherInfo.type" :label="3">退休</el-radio>
                  </el-form-item>
                </div>
                <div style="display: flex">
                    <el-form-item prop="time">
                        <div class="block">
                            <el-date-picker
                                v-if="otherInfo.type !== 1"
                                size="small"
                                :key="otherInfo.type"
                                v-model="otherInfo.time"
                                :picker-options="pickerOptions"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :unlink-panels="true">
                            </el-date-picker>
                          <el-date-picker
                              v-else
                              size="small"
                              :key="otherInfo.type"
                              :picker-options="pickerOptions"
                              v-model="otherInfo.time"
                              type="date"
                              value-format="yyyy-MM-dd">
                          </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'projectInfo'" :model="otherInfo" :rules="projectRules" :ref="type" class="demo-ruleForm">
          <div style="display: flex">
            <el-form-item prop="name" style="margin-bottom: 20px; flex: 1; margin-right: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">项目名称</span></div>
              <el-input type="text"
                        resize="none"
                        :rows="2"
                        :show-word-limit="true"
                        v-model="otherInfo.name">
              </el-input>
            </el-form-item>
            <el-form-item prop="address" style="margin-bottom: 20px; flex: 1;">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">项目地点</span></div>
              <el-input type="text"
                        resize="none"
                        maxlength="50"
                        :show-word-limit="true"
                        v-model="otherInfo.address">
              </el-input>
            </el-form-item>
          </div>
            <el-form-item prop="content" style="margin-bottom: 20px; flex: 1">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">项目实施详情</span></div>
                  <el-input type="textarea"
                            resize="none"
                            :rows="4"
                            v-model="otherInfo.content"
                            maxlength="500"
                            :show-word-limit="true">
                  </el-input>
            </el-form-item>
          <el-form-item prop="time">
            <div class="block">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">项目时间</span></div>
              <el-date-picker
                  size="small"
                  v-model="otherInfo.time"
                  :picker-options="pickerOptions"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :unlink-panels="true">
              </el-date-picker>
            </div>
          </el-form-item>

            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'honorInfo'" :model="otherInfo" :rules="honorRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉名称</span></div>
                <el-input type="text"
                          resize="none"
                          v-model="otherInfo.name"
                          maxlength="50"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉详情</span></div>
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          v-model="otherInfo.content"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
          <el-form-item prop="time">
            <div class="block">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉时间</span></div>
              <el-date-picker
                  size="small"
                  v-model="otherInfo.time"
                  :picker-options="pickerOptions"
                  type="date"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item prop="images">
            <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉证书</span></div>
            <AuthImage :images="otherInfo.images" @getImages="getImages"></AuthImage>
          </el-form-item>

            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'professionalInfo'" :model="otherInfo" :rules="professionalTitleRules" :ref="type" class="demo-ruleForm">
          <div style="display: flex;">
            <el-form-item prop="name" style="margin-bottom: 20px; flex: 1; margin-right: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">证书名称</span></div>
              <el-input type="text"
                        resize="none"
                        maxlength="50"
                        :disabled="isEdit"
                        :show-word-limit="true"
                        v-model="otherInfo.name">
              </el-input>
            </el-form-item>
            <el-form-item prop="major" style="margin-bottom: 20px; flex: 1">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">专业</span></div>
              <el-input type="text"
                        resize="none"
                        maxlength="20"
                        :show-word-limit="true"
                        v-model="otherInfo.major">
              </el-input>
            </el-form-item>
          </div>

          <el-form-item prop="organization" style="margin-bottom: 20px; flex: 1; margin-right: 20px">
            <div><span style="color: #FF0000">*</span><span style="color: #999999;">评审机构</span></div>
            <el-input type="text"
                      resize="none"
                      :disabled="isEdit"
                      maxlength="50"
                      :show-word-limit="true"
                      v-model="otherInfo.organization">
            </el-input>
          </el-form-item>

            <div style="display: flex;">
                <div class="resume-title">
                    职称等级
                </div>
                <el-form-item prop="lv" style="margin: 0 0 30px 100px;">
                    <div style="line-height: 30px">
                        <el-radio v-model="otherInfo.lv" :label="1">中级</el-radio>
                        <el-radio v-model="otherInfo.lv" :label="2">高级</el-radio>
                        <el-radio v-model="otherInfo.lv" :label="3">提高</el-radio>
                    </div>
                </el-form-item>
              <div style="margin-left: 50px">
                <el-form-item prop="time" style="margin: 0 0 30px 100px;">
                <el-date-picker
                    size="small"
                    v-model="otherInfo.time"
                    :picker-options="pickerOptions"
                    type="date"
                    placeholder="证书日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
                </el-form-item>
              </div>
            </div>
          <el-form-item prop="images">
            <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">职称证书</span></div>
            <AuthImage :images="otherInfo.images" @getImages="getImages"></AuthImage>
          </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'NVQCartInfo'" :model="otherInfo" :rules="NVQCartRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">证书名称</span></div>
                <el-input type="text"
                          resize="none"
                          maxlength="50"
                          :disabled="isEdit"
                          :show-word-limit="true"
                          v-model="otherInfo.name">
                </el-input>
            </el-form-item>
            <div style="margin-bottom: 20px">
              <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">证书日期</span></div>
              <el-form-item prop="time">
                <el-date-picker
                    size="small"
                    v-model="otherInfo.time"
                    :picker-options="pickerOptions"
                    type="date"
                    placeholder="证书日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </div>
          <el-form-item prop="images">
            <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">职业资格证书</span></div>
            <AuthImage :images="otherInfo.images" @getImages="getImages"></AuthImage>
          </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="type === 'otherCartInfo'" :model="otherInfo" :rules="otherCartRules" :ref="type" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 20px">
              <div><span style="color: #FF0000">*</span><span style="color: #999999;">证书名称</span></div>
                <el-input type="text"
                          resize="none"
                          :disabled="isEdit"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="otherInfo.name">
                </el-input>
            </el-form-item>
          <el-form-item prop="images">
            <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">其他证书</span></div>
            <AuthImage :images="otherInfo.images" @getImages="getImages"></AuthImage>
          </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
                    <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
                </div>
            </div>
        </el-form>
      <el-form v-if="type === 'otherInfo'" :model="otherInfo" :rules="otherRules" :ref="type" class="demo-ruleForm">
        <el-form-item prop="name" style="margin-bottom: 20px">
          <div><span style="color: #FF0000">*</span><span style="color: #999999;">佐证名称</span></div>
          <el-input type="text"
                    resize="none"
                    maxlength="50"
                    :show-word-limit="true"
                    v-model="otherInfo.name">
          </el-input>
        </el-form-item>
        <el-form-item prop="content" style="margin-bottom: 20px">
          <div><span style="color: #FF0000">*</span><span style="color: #999999;">说明</span></div>
          <el-input type="textarea"
                    resize="none"
                    :rows="4"
                    v-model="otherInfo.content"
                    maxlength="500"
                    :show-word-limit="true">
          </el-input>
        </el-form-item>
        <el-form-item prop="images">
          <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">佐证材料</span></div>
          <AuthImage :images="otherInfo.images" @getImages="getImages"></AuthImage>
        </el-form-item>
        <el-divider></el-divider>
        <div style="display: flex; justify-content: end">
          <div>
            <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
            <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
            <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
          </div>
        </div>
      </el-form>
    </div>
</template>

<script>

import Editor from "@/pages/circle/components/Editor.vue";
import AuthImage from "@/pages/circle/components/Authentication/AuthImage.vue";

export default {
    name: "OtherFrom",
    components: {AuthImage, Editor},
    data() {
        return {
            otherInfo: this.otherData,
            images: [],
            cartImg: '',

            //  工作
            workRules: {
              companyName: [
                    {required: true, message: '请填写公司名称', trigger: 'blur'}
                ],
                position: [
                    {required: true, message: '请填写职位名称', trigger: 'blur'}
                ],
                address: [
                    {required: true, message: '请公司地址', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请填写说明情况', trigger: 'blur'}
                ],
                type: [
                  {required: true, message: '请选择状态', trigger: 'blur'}
                ],
                time: [
                    {required: true, message: '请选择时间', trigger: 'blur'}
                ]
            },

            projectRules: {
                name: [
                    {required: true, message: '请填写项目名称', trigger: 'blur'}
                ],
                address: [
                  {required: true, message: '请填写项目地点', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请填写项目实施情况', trigger: 'blur'}
                ],
                time: [
                  {required: true, message: '请填写时间范围', trigger: 'blur'}
                ]
            },

            honorRules: {
                name: [
                    {required: true, message: '请填写荣誉名称', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请填写荣誉详情', trigger: 'blur'}
                ],
                time: [
                  {required: true, message: '请填写荣誉时间', trigger: 'blur'}
                ],
                images: [
                  {required: true, message: '请上传荣誉证书', trigger: 'change'}
                ]
            },

            professionalTitleRules: {
                name: [
                    {required: true, message: '请填写职称名称', trigger: 'blur'}
                ],
                major: [
                  {required: true, message: '请填写专业名称', trigger: 'blur'}
                ],
                time: [
                  {required: true, message: '请填写职称时间', trigger: 'blur'}
                ],
                organization: [
                  {required: true, message: '请填写评审机构名称', trigger: 'blur'}
                ],
                lv: [
                    {required: true, message: '请选择职称等级', trigger: 'change'}
                ],
                images: [
                  {required: true, message: '请上传职称证书', trigger: 'change'}
                ],
            },

            NVQCartRules: {
                name: [
                    {required: true, message: '请填写职业资格名称', trigger: 'blur'}
                ],
                time: [
                  {required: true, message: '请填写职业资格时间', trigger: 'blur'}
                ],
                images: [
                  {required: true, message: '请上传职称证书', trigger: 'change'}
                ],
            },

            otherCartRules: {
              name: [
                {required: true, message: '请填写证书名称', trigger: 'blur'}
              ],
              images: [
                {required: true, message: '请上传证书', trigger: 'change'}
              ],
            },

            otherRules: {
                name: [
                    {required: true, message: '请填写证书名称', trigger: 'blur'}
                ],
                content: [
                  {required: true, message: '请填写说明情况', trigger: 'blur'}
                ],
                images: [
                  {required: true, message: '请上传佐证证书', trigger: 'blur'}
                ]
            },
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() >= Date.now();
            }
          },

        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    props: {
        otherData: {
            type: Object,
            default() {
                return {}
            }
        },

        type: {
            type: String,
            default() {
                return '';
            }
        },

        isEdit: {
          type: Boolean,
          default() {
            return false;
          }
        }
    },
    created: function () {

    },
    mounted: function () {
      if (this.isEdit && this.otherInfo.images) {
        this.images = this.otherInfo.images
      }
    },
    methods: {
        getImages(e) {
          this.images = e
        },

        changeType() {
          if (this.otherInfo.type === 1) {
            this.$set(this.otherInfo, "time", '');
          } else {
            this.$set(this.otherInfo, "time", []);
          }
        },

        cancelInfo() {
            this.$emit('reMajor', this.type)
        },

        // 保存表单
        saveInfo() {
            let that = this;
            if (that.images.length > 0) {
              that.otherInfo.images = that.images;
              this.$set(this.honorRules, 'images', [])
              this.$set(this.otherRules, 'images', [])
              this.$set(this.professionalTitleRules, 'images', [])
              this.$set(this.NVQCartRules, 'images', [])
              this.$set(this.otherCartRules, 'images', [])
            }
            that.$refs[that.type].validate(function (valid) {
                if (valid) {
                  let params = {};
                  params = that.otherInfo;
                  params.isShow = 0
                  that.$emit('pushOtherData', params)
                }
            })
        },

        // 编辑表单
        upInfo() {
            let that = this;
            if (that.images.length > 0) {
              that.otherInfo.images = that.images;
            }
            that.$refs[that.type].validate(function (valid) {
                if (valid) {
                    let params = {};
                    params = that.otherInfo;
                    that.$emit('upOtherData', params)
                }
            })
        },

        // 上传图片
        // uploadFrontImg(params, e) {
        //     var that = this;
        //     var file = params.file;
        //     const isLt5M = file.size / 1024 / 1024 < 10;
        //     if (isLt5M) {
        //         if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
        //             that.utils.upload(file,function(url){
	      //               if (!url){
		    //                 return false;
	      //               }
        //               if (that.images.length === 2) {
        //                 that.images.splice(e,1,url.url)
        //               } else {
        //                 that.images.push(url.url)
        //               }
        //             })
        //         } else {
        //             this.$message.error('只能上传图片类型!')
        //         }
        //
        //     } else {
        //         this.$message.error('上传文件大小不能超过 10MB!')
        //     }
        // }
    }
}
</script>

<style scoped>
.open-btn {
    width: 76px;
    height: 38px;
    padding: 2px 0;
    line-height: 6px;
    background-color: #E4E6EB;
    border-radius: 5px;
}

.save-btn {
  width: 98px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}

.cancel-btn {
  width: 98px;
  height: 38px;
  margin-right: 18px;
  background-color: #E4E6EB;
  border-radius: 5px;
}

.resume-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

.upload-demo {
    vertical-align: middle;
}

.el-icon-plus {
    color: #2970FF;
    font-size: 44px;
    font-weight: 600;
    text-shadow: 0 3px 6px rgba(0,0,0,0.16);
    margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
    width: 516px;
    height: 338px;
    background-color: #FBFBFB;
}

/deep/ .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: #1C7EFF 2px solid;
}

/deep/ .el-checkbox__inner::after {
    left: 6px;
    height: 9px;
    width: 4px;
}

/deep/ .el-upload-dragger {
    width: 500px;
    height: 300px;
    background-color: #FBFBFB;
}
</style>
